import { combineReducers } from "redux";
import {
  fetchNonce,
  fetchAuthData,
  fetchCategoryList,
  fetchCollectionList,
  fetchNewNFTId,
  fetchCreateCollection,
  fetchNewLAZYNFTId,
  fetchUserProfile,
  fetchResponseFailed,
  fetchUpdatedNFTId,
  drops,
} from "./defi.reducer";
import {
  fetchDashboardBanners,
  fetchDashboard,
  fetchDashboardInfo,
  fetchDashboardHallFrameInfo,
  fetchCreators,
  fetchPagination,
  fetchMoreCreators,
  fetchMarketPlaceNFT,
  fetchMoreMarketPlaceNFT,
  fetchCollections,
  fetchCollectionDetails,
  fetchMoreCollections,
  updateCollection,
  fetchTopNFT,
  fetchTopCollection,
  fetchProfileInfo,
  fetchHallOfFrameCollector,
  fetchHallOfFrameArtwork,
  fetchHallOfFrameArtist,
} from "./auth.reducer";
import {
  fetchNFTContractInstance,
  fetchNetworkId,
  fetchWeb3Data,
} from "./web3.reducer";
import {
  fetchCategory,
  updateProfile,
  fetchUserNFT,
  fetchNewCollection,
  fetchUserDraftNFT,
  fetchSingleNFTDetails,
  fetchProfile,
  fetchLikesCount,
  fetchIsLiked,
  fetchIsFollow,
  fetchLikedNFT,
  fetchCollectedNFT,
  fetchCollectionNFT,
  fetchNFTEditionHistory,
  fetchNotifications,
  fetchProfileBanner,
  fetchLanguage,
  verified_by_instagram,
  fetch_twitter_access_token,
  verified_by_twitter,
  fetchListToken,
  otherNFTs,
  NFTRankings,
  singleDropDetails,
} from "./user.reducer";

const rootReducer = combineReducers({
  singleDropDetails,
  fetchLanguage,
  fetchIsLiked,
  fetchLikesCount,
  fetchCreateCollection,
  fetchSingleNFTDetails,
  fetchUserDraftNFT,
  fetchNewCollection,
  fetchCategoryList,
  fetchCollectionList,
  fetchNewNFTId,
  fetchNewLAZYNFTId,
  fetchAuthData,
  fetchNonce,
  fetchNFTContractInstance,
  fetchNetworkId,
  fetchDashboardBanners,
  fetchDashboardInfo,
  fetchDashboard,
  fetchWeb3Data,
  fetchCategory,
  fetchDashboardHallFrameInfo,
  updateProfile,
  fetchUserNFT,
  fetchCreators,
  fetchPagination,
  fetchMoreCreators,
  fetchMarketPlaceNFT,
  fetchMoreMarketPlaceNFT,
  fetchCollections,
  fetchCollectionDetails,
  fetchUserProfile,
  fetchProfile,
  fetchResponseFailed,
  fetchMoreCollections,
  updateCollection,
  fetchIsFollow,
  fetchTopNFT,
  fetchTopCollection,
  fetchLikedNFT,
  fetchCollectedNFT,
  fetchCollectionNFT,
  fetchNFTEditionHistory,
  fetchNotifications,
  fetchProfileBanner,
  fetchProfileInfo,
  fetchUpdatedNFTId,
  fetchHallOfFrameCollector,
  fetchHallOfFrameArtwork,
  fetchHallOfFrameArtist,
  verified_by_instagram,
  fetch_twitter_access_token,
  verified_by_twitter,
  fetchListToken,
  otherNFTs,
  drops,
  NFTRankings,
});

export default rootReducer;
