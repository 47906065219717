import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import Wrapper from "./Component/wrapper";
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  zora,
  bscTestnet,
} from "wagmi/chains";
import merge from "lodash.merge";
import {
  injectedWallet,
  coinbaseWallet,
  trustWallet,
  metaMaskWallet,
  ledgerWallet,
  walletConnectWallet,
  //   bitskiWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import { darkTheme, Theme } from "@rainbow-me/rainbowkit";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
const apiId = "1n0lQK1mdU_IF6manRcrLS__wcx-wNwP";
const { chains, publicClient } = configureChains(
  [bscTestnet],
  [alchemyProvider({ apiKey: process.env.ALCHEMY_ID }), publicProvider()]
);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: connectorsForWallets([
    {
      groupName: "Recommended",
      wallets: [
        coinbaseWallet({ apiId, chains }),
        // trustWallet({ apiId, chains }),
        metaMaskWallet({ apiId, chains }),
        // ledgerWallet({ apiId, chains }),
        // walletConnectWallet({
        //   options: {
        //     projectId: apiId,
        //     chains: chains,
        //   },
        // }),
        // bitskiWallet({ apiId, chains }),
        injectedWallet({ chains, options: { name: "Injected" } }),
      ],
    },
  ]),
  publicClient,
});
const myTheme = merge(darkTheme(), {
  colors: {
    accentColor: "#d5d6da",
  },
});

ReactDOM.render(
  <Wrapper>
    <Provider store={store}>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider coolMode theme={myTheme} chains={chains}>
          <App />
        </RainbowKitProvider>
      </WagmiConfig>
    </Provider>
  </Wrapper>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
